<template>
  <form class="opportunity-map-section" @submit.prevent>
    <UiContainer large class="tw-mt-8 tw-pb-24">
      <v-card class="tw-p-6">
        <UiTitle :title="$t('opportunity.configuration.map.title')" class="tw-mb-8" large />

        <OpportunityFieldsTitleDescription
          :label="$t('opportunity.configuration.map.mapId.label')"
          :description="$t('opportunity.configuration.map.mapId.description')"
        >
          <template #content>
            <v-text-field
              class="tw-mt-4"
              v-model="mapModel.id"
              flat
              dense
              outlined
              clearable
              :placeholder="$t('opportunity.configuration.map.mapId.label')"
            />
          </template>
        </OpportunityFieldsTitleDescription>
      </v-card>

      <UiActions v-if="isModified" centered>
        <v-btn color="primary" type="submit" large @click="updateMap">
          {{ $t('opportunity.configuration.save') }}
        </v-btn>
      </UiActions>
    </UiContainer>
  </form>
</template>

<script>
import UiActions from '@/components/UI/Actions.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiContainer from '@/components/UI/Container.vue'
import OpportunityFieldsTitleDescription from '@/components/Opportunity/Fields/TitleDescription.vue'

export default {
  props: {
    map: {
      type: Object,
      required: true,
    },
  },
  components: {
    UiActions,
    UiTitle,
    UiContainer,
    OpportunityFieldsTitleDescription,
  },
  data() {
    return {
      mapModel: {
        id: null,
      },
    }
  },
  created() {
    this.mapModel = {
      ...this.mapModel,
      ...this.map,
    }
  },
  methods: {
    updateMap() {
      this.$emit('update', this.mapModel)
    },
  },
  computed: {
    isModified() {
      return JSON.stringify(this.map) !== JSON.stringify(this.mapModel)
    },
  },
}
</script>
